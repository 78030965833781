import React from 'react';
import Proptypes from 'prop-types';
import Scrollbars from 'react-custom-scrollbars';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Button from '../../Components/Button';
import Confirm from './ConfirmComponent';
import casesObj from '../../Translations/en/Unsubscribe';
import {
  unsubscribeUser,
  cleanUnsusbcribeProcess,
  getUnsubscribeCase,
} from '../../Actions/userActions';
import { statusLoading } from '../../Actions/loadingActions';
import snackBarStatus from '../../Actions/snackbarActions';
import SnackBar from '../../Components/Snackbar';
import defaultOrg from '../../Theme/Icons/org-icon.svg';
import LoadingScreen from '../../Components/LoadingScreen';
import Loading from '../../Components/Loading';

function doNothing() {}

class UnsubscribeComponent extends React.Component {
  static propTypes = {
    match: Proptypes.instanceOf(Object).isRequired,
    // eslint-disable-next-line
    cases: Proptypes.instanceOf(Object),
  };

  static defaultProps = {
    cases: casesObj,
  };

  state = {
    confirmAction: false,
    afterConfirm: false,
  };

  /*
    LIFECYCLE METHODS ===================================================
  */

  componentDidMount() {
    const {
      match: {
        params: { groupId, memberId },
      },
      history,
      unsubscribeProcess,
    } = this.props;
    if (!unsubscribeProcess) {
      this.props
        .getUnsubscribeCase(groupId, memberId)
        .then(() => {
          this.setState({
            confirmAction: true,
          });
        })
        .catch(err => {
          history.push('/not-subscribed');
          // eslint-disable-next-line no-console
          console.error(err);
        });
    }
  }

  componentDidUpdate() {
    const { unsubscribeProcess, history } = this.props;
    if (!unsubscribeProcess.case) {
      history.push('/not-subscribed');
    }
  }

  componentWillUnmount() {
    if (this.props.cleanUnsusbcribeProcess) this.props.cleanUnsusbcribeProcess();
  }

  /*
   COMPONENT METHODS ===================================================
  */

  /**
   * Performs the action to unsubscribe the user from the group or organization.
   * If a problem arises it should display a snackbar with error.
   */
  unsubscribeUserFromGroup = async () => {
    try {
      this.props.statusLoading(true);
      const {
        match: {
          params: { groupId, memberId, token },
        },
      } = this.props;
      await this.props.unsubscribeUser(groupId, memberId, token);
      this.setState({
        confirmAction: false,
        afterConfirm: true,
      });
      this.props.statusLoading(false);
    } catch (err) {
      // For the moment, a snackbar
      // eslint-disable-next-line no-console
      console.error(err);
      this.props.statusLoading(false);
      this.props.snackBarStatus({
        payload: {
          enable: true,
          title: 'There was a problem finishing the unsusbcribe process.',
          type: 'error',
        },
      });
    }
  };

  render() {
    const { confirmAction, afterConfirm } = this.state;
    if (!this.props.unsubscribeProcess) {
      return <LoadingScreen />;
    }
    const {
      cases,
      unsubscribeProcess: {
        case: caseVal,
        information: {
          organization: { main_picture_url: mainPictureUrl, name: orgName },
          name,
        },
      },
    } = this.props;
    let retval;
    let buttonsSection;
    if (confirmAction) {
      buttonsSection = [
        <Button
          title={cases[caseVal].yesButtonMessage}
          onClick={this.unsubscribeUserFromGroup}
          className="wide-button"
        />,
        <Link style={{ textDecoration: 'none' }} to="/login">
          <Button
            title={cases[caseVal].noButtonMessage}
            onClick={doNothing}
            className="wide-button"
          />
        </Link>,
      ];
      retval = (
        <Confirm
          focusText={cases[caseVal].question}
          auxText={cases[caseVal].warningText}
          group={{ name, organization: orgName }}
          groupOrgPicture={mainPictureUrl || defaultOrg}
          buttonsFragment={buttonsSection}
        />
      );
    } else if (afterConfirm) {
      buttonsSection = [
        <Link style={{ textDecoration: 'none' }} to="/login">
          <Button
            title="Evangelus account login"
            onClick={doNothing}
            className="evangelus-account-login"
          />
        </Link>,
      ];
      retval = (
        <Confirm
          focusText={cases[caseVal].afterConfirmText}
          auxText={cases[caseVal].finalNotice}
          group={{ name, organization: orgName }}
          groupOrgPicture={mainPictureUrl || defaultOrg}
          buttonsFragment={buttonsSection}
          afterConfirm
        />
      );
    } else {
      return <div />;
    }
    return (
      <Scrollbars autoHeight autoHide autoHeightMin={0} autoHeightMax="100vh">
        {retval}
        <SnackBar />
        <Loading />
      </Scrollbars>
    );
  }
}

const mapStateToProps = ({ userProfileReducer: { unsubscribeProcess } }) => ({
  unsubscribeProcess,
});

const mapDispatcActions = {
  unsubscribeUser,
  cleanUnsusbribeProcess: cleanUnsusbcribeProcess,
  getUnsubscribeCase,
  snackBarStatus,
  statusLoading,
};

export default connect(
  mapStateToProps,
  mapDispatcActions,
)(UnsubscribeComponent);
