import Profile from '../Api/CompleteProfile';
import snackBarStatus from './snackbarActions';
import { clearInvitation } from './invitationActions';
import { getUniqStates } from '../Helpers/ArrayUtils';
import { redirectToLogin } from '../Helpers/redirectToLogin';
import Location from '../Api/Location';

export const ACTIONS = {
  CLEAR: '[Complete profile] Clear',
  ERROR: '[Complete profile] Error',
  GET: '[Complete profile] Get user information',
  RESET: '[Complete profile] Reset',
  UPDATE: '[Complete profile] Update user information',
  UPDATE_DATA: '[Complete profile] Update data',
  SET_TOKEN: '[Complete profile] Set token',
  LOADING: '[Complete profile] Loading',
};

const setLoading = bool => dispatch => {
  dispatch({
    type: ACTIONS.LOADING,
    payload: bool,
  });
}

export const getUserAndGroupInformation = (
  groupId,
  token,
) => async dispatch => {
  setLoading(true)(dispatch);
  try {
    const response = await Profile.getUserAndGroupInformation(groupId, token);
    if (response.status === 200) {
      if (response.data.data.member.status === 'active') {
        redirectToLogin();
      } else {
        dispatch({ type: ACTIONS.GET, payload: response.data.data });
        clearInvitation();
      }
      setLoading(false)(dispatch);
    }
    return response;
  } catch (error) {
    // redirectToLogin();
    setLoading(false)(dispatch);
    snackBarStatus({
      payload: {
        title: error.message,
        type: 'error',
        enable: true,
      },
    })(dispatch);
    return error;
  }
};

export const updateData = payload => dispatch => {
  dispatch({ type: ACTIONS.UPDATE_DATA, payload });
};

export const createUserProfile = password => async (dispatch, getState) => {
  try {
    setLoading(true)(dispatch);

    const {
      completeProfileReducer: {
        firstName,
        lastName,
        birthDate,
        phoneNumber,
        email,
        selectedCountry,
        selectedState,
        city,
        street,
        contactPreference,
        postalCode
      },
      invitationReducer: {
        group: { public_id: groupId }
      }
    } = getState();
    const req = {
      birthDate,
      city,
      country: selectedCountry ? selectedCountry.public_id : null,
      email,
      firstName,
      forward_reply_sms_number: contactPreference === 'sms',
      forward_reply_email: contactPreference === 'email',
      group: groupId,
      lastName,
      phone: phoneNumber,
      postal_code: postalCode,
      province: selectedState ? selectedState.name : null,
      password,
      stateIsoCode: selectedState ? selectedState.state_iso_code : null,
      street,
    };

    const response = await Profile.createUserProfile(req);
    let userProfile = {};

    if (response.status === 200) {
      userProfile = response.data;
      dispatch({ type: ACTIONS.UPDATE, payload: userProfile });
      clearInvitation()(dispatch);
    }

    setLoading(false)(dispatch);

    return response;
  } catch (error) {
    console.error(error);
    snackBarStatus({
      payload: {
        title: error.message,
        type: 'error',
        enable: true,
      },
    })(dispatch);

    setLoading(false)(dispatch);

    return error;
  }
};

export const updateUserProfile = (password, token) => async (dispatch, getState) => {
  try {
    setLoading(true)(dispatch);

    const {
      completeProfileReducer: {
        firstName,
        lastName,
        birthDate,
        phoneNumber,
        email,
        selectedCountry,
        selectedState,
        city,
        street,
        contactPreference,
        postalCode,
        group,
      },
      invitationReducer: {
        group: { public_id: groupId }
      }
    } = getState();

    const req = {
      birthDate,
      city,
      countryPublicId: selectedCountry ? selectedCountry.public_id : null,
      email,
      firstName,
      groupId,
      forward_reply_sms_number: contactPreference === 'sms',
      forward_reply_email: contactPreference === 'email',
      lastName,
      phone: phoneNumber,
      postal_code: postalCode,
      province: selectedState ? selectedState.name : null,
      password,
      stateIsoCode: selectedState ? selectedState.state_iso_code : null,
      street,
      token,
    };

    const response = await Profile.updateUserProfile(group.public_id, token, req);

    let userProfile = {};
    if (response.status === 200) {
      userProfile = response.data;
      dispatch({ type: ACTIONS.UPDATE, payload: userProfile });
    }

    setLoading(false)(dispatch);
    return response;
  } catch (error) {

    snackBarStatus({
      payload: {
        title: error.message,
        type: 'error',
        enable: true,
      },
    })(dispatch);

    setLoading(false)(dispatch);

    return error;
  }
};
