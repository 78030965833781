import React, { Component } from 'react';
import { connect } from 'react-redux';
import { DisabledChipsField } from '../../Components/DisabledCipsField';
import GroupsPicker from '../../Components/GroupsPicker';
import { updateMessageSubject, updateRecipientGroups } from '../../Actions/messageActions';
import { debounce } from '../../Helpers/debounce';
import getMemberInfo from '../../Helpers/getMemberInfo';
import './index.sass';

class MessageHeadSettings extends Component {

  state = { subject: '' }

  updateSubjectWithDebounce = debounce(value => this.props.updateMessageSubject(value), 500);

  componentDidMount() {
    this.setState({ subject: this.props.subject });
  }

  handleSubjectChange = e => {
    const value = e.target.value;
    this.setState({ subject: value });
    this.updateSubjectWithDebounce(value);
  }

  render() {
    const { groupsDictionaryList, isResendUnopened, recipientGroups, selectedMember, validationErrors } = this.props;

    return (
      <div className="message-head-settings">
        <input
          className={`input-message-subject ${validationErrors.subject ? 'error' : ''}`}
          type="text"
          maxLength={50}
          name="subject"
          placeholder="Subject"
          value={this.state.subject}
          onChange={this.handleSubjectChange}
        />

        {isResendUnopened && <DisabledChipsField data={['Unopened Recipients']}/>}

        {selectedMember && selectedMember.publicId && <DisabledChipsField data={[getMemberInfo(selectedMember).displayedFullName]}/>}

        {!isResendUnopened && !selectedMember &&
        <GroupsPicker
          dictionary={groupsDictionaryList}
          values={recipientGroups}
          isInvalid={validationErrors.recipient}
          onUpdate={ids => this.props.updateRecipientGroups(ids, true)}
        />
        }
      </div>
    );
  }
}

const mS = state => ({
  isResendUnopened: state.messageReducer.isResendUnopened,
  groupsDictionaryList: state.groupsDictionaryReducer.list,
  recipientGroups: state.messageReducer.recipientGroups,
  selectedMember: state.messageReducer.selectedMember,
  subject: state.messageReducer.subject,
  validationErrors: state.messageReducer.validationErrors,
});

const mD = {
  updateMessageSubject,
  updateRecipientGroups
};

export default connect(mS, mD)(MessageHeadSettings);
