import React, { Component, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import EditMemberPropTypes from '../../PropTypes/EditMemberPropType';
import { enableRightDrawer } from '../../Actions/rightDrawerShortActions';
import ButtonStandard from '../Button';
import { InputIconGridless as Input } from '../InputIcon';
import snackBarStatus from '../../Actions/snackbarActions';
import { deleteAccount } from '../../Actions/userActions';
import EditPicture from '../EditPicture';
import {
  displayEditMemberForm,
  editMember,
  editProfileUser,
  getMemberGroups,
  restoreSelectedMember,
  updateDisplayChangePassword,
  updateMember,
  updateMemberGroups,
} from '../../Actions/memberActions';
import {
  getAllGroupAsync,
  getGroupsAsync,
  getMyGroupAsync,
  getOrgAsync,
  removeMemberFromGroup,
  updateGroupsReducer,
} from '../../Actions/groupsActions';
import PhotoEditMember from '../../Theme/Icons/editprofile.svg';
import '../EditMember/index.sass';
import { InputPhone } from './../InputPhone';
import { getDateToday } from './../../Helpers/date';
import MemberPictureEdit from './../MemberPictureEdit';
import style from './../EditMember/styles';
import { Select } from '@mobiscroll/react';
import { mbscResponsiveOptions } from '../../Config/Constants/mobiscroll-props';
import BirthdayPicker from '../BirthdayPicker';
import CountryPicker from '../CountryPicker';
import './index.sass';
import StatePicker from '../StatePicker';
import CountryPreview from '../CountryPreview';
import StatePreview from '../StatePreview';

/* eslint-disable */
const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

/* eslint-enable */

const EditMemberProfile  = ({
  classes, myGroups, forceReload, selectedMember, organization,
  selectedMember: {
    publicId,
    firstName,
    lastName,
    email,
    phoneNumber,
    referenceAddress,
    cityText,
    countryPublicId,
    statePublicId,
    postalCode,
    birthDate,
    profilePictureUrl,
},
  updateMember, editProfileUser, getMyGroupAsync, deleteAccount,
  changeDisplay, snackBarStatus, enableRightDrawer, displayEditMemberForm, updateDisplayChangePassword, restoreSelectedMember,
}) => {
  const [cachedFields, setCachedFields] = useState({});
  const [isDialogVisible, setIsDialogVisible] = useState(false);
  const [dateValue, setDateValue] = useState(birthDate);
  const [isConfirmDeleteDisabled, setIsConfirmDeleteDisabled] = useState(false);
  const [validationState, setValidationState] = useState({
    firstName: { valid: false, stateName: 'firstNameValidation' },
    lastName: { valid: false, stateName: 'lastNameValidation' },
    email: { valid: false, stateName: 'emailValidation' },
    phone: { valid: false, stateName: 'phoneValidation' },
    referenceAddress: { valid: false, stateName: 'streetValidation' },
    cityText: { valid: false, stateName: 'cityValidation' },
    state: { valid: false, stateName: 'stateValidation' },
    postalCode: { valid: false, stateName: 'postalCodeValidation' },
  })

  const { pathname } = window.location;
  const profilePicSelected = Boolean(profilePictureUrl);

  useEffect(() => {
    setCachedFields(selectedMember);
  }, []);

  const handleCountryChange = (id) => updateMember({ countryPublicId: id });

  const handleStateChange = (id) => updateMember({ statePublicId: id });

  const handleError = (err = '') => {
    snackBarStatus({
      payload: {
        enable: true,
        title: err,
        type: 'formError',
      },
    });
  };

  const handleChange = (e, attr) => {
    const payload = {};
    payload[`${attr}`] = e.target.value;
    updateMember(payload);
  };
  const handlePhone = phoneNumber => {
    updateMember({ phoneNumber });
  };

  const checkProfileImage = photo => {
    let Photo = PhotoEditMember;
    let forceReloadParam = '';
    if (forceReload) {
      forceReloadParam = `?val=${forceReload}`;
    }
    if (photo) {
      Photo = `${photo}${forceReloadParam}`;
    }

    return Photo;
  };
  const handleChangeBirthday = date => {
    const dateFormat = date.valueText ? moment(date.valueText).format('YYYY-MM-DD') : null;
    if (dateFormat > getDateToday()) {
      snackBarStatus({
        payload: {
          enable: true,
          title: 'The birthday date must not be higher than the current date',
          type: 'error',
        },
      });
    } else {
      setDateValue(dateFormat)
      updateMember({ birthDate: dateFormat });
    }
  };

  const changePicture = e => {
    e.preventDefault();
    if (window.location.pathname !== '/invited-complete-profile') {
      enableRightDrawer({
        payload: {
          enable: true,
          element: <EditPicture userTypeToEditPicture="current-session-user"/>,
          title: 'Select a profile picture',
          userTypeToEditPicture: 'current-session-user',
        },
      });
    }
    if (changeDisplay) {
      changeDisplay({ displayPicture: true });
    }
  };
  const handleValidations = data => {
    const newValidationState = { ...validationState };
    Object.keys(newValidationState).forEach(key => {
      newValidationState[key].valid = false;
    });
    const validationErrors = [];
    if (data.phoneNumber && typeof data.phoneNumber === 'string' && data.phoneNumber.length) {
      const parsedNumber = parsePhoneNumberFromString(data.phoneNumber);
      if (!parsedNumber || !parsedNumber.isValid()) {
        newValidationState.phone.valid = true;
        validationErrors.push('Invalid phone number');
        // return 'Invalid phone number';
      }
    }
    if (data.email && !emailRegex.test(data.email)) {
      validationErrors.push('Invalid email');
      // return 'Invalid email';
    }
    setValidationState(newValidationState);
    return validationErrors.length ? validationErrors : null;
  };

  const handleSubmit = e => {
    e.preventDefault();

    // for the time of this edit, the default b-day value for a member is
    // moment() AKA Date.now()
    const data = {
      publicId,
      firstName,
      lastName,
      birthday: birthDate,
      phoneNumber,
      email,
      referenceAddress,
      cityText,
      postalCode,
      countryPublicId,
      statePublicId,
      groups: myGroups.length > 0
        && myGroups[0].groups.length > 0 && myGroups[0].groups[0].group,
    };
    const errorMessages = handleValidations(data);
    if (errorMessages) {
      handleError(errorMessages);
      return;
    }
    displayEditMemberForm(false);
    updateDisplayChangePassword(true);
    editProfileUser(data).then(response => {
      if (response.code === 200) {
        snackBarStatus({
          payload: {
            enable: true,
            title: 'Member has been Updated',
            type: 'success',
          },
        });
        changeDisplay(false);
        getMyGroupAsync(organization);
      } else {
        snackBarStatus({
          payload: {
            title: response.message,
            type: 'error',
            enable: true,
          },
        });
      }
    });
  };

  const handleOpen = () => setIsDialogVisible(true);

  const handleDeleteAccount = () => {
    deleteAccount(); /* .then(() => {
      window.location = '/delete-account';
    }); */
    setIsConfirmDeleteDisabled(true);
  };

  const handleClose = () => setIsDialogVisible(false);

  const handleEditCancel = () => {
    restoreSelectedMember(cachedFields);
    changeDisplay({
      displayEdit: false,
      displayPicture: false,
    });
    displayEditMemberForm(false);
    updateDisplayChangePassword(true);
  }

  return (
    <div className="view-member-wrapper edit-mode" id="edit-member-form">
      <div>
        <div>

          <MemberPictureEdit
            isNoImage={!profilePicSelected}
            imageUrl={checkProfileImage(profilePictureUrl)}
            onChange={changePicture}
          />
        </div>

        <div className="input-edit-member">
          <Input
            type="text"
            name="firstName"
            value={firstName || ''}
            placeholder="First name"
            icon="firstName"
            onChange={handleChange}
            tooltipTitle="Name"
            useId="special"
            className={validationState.firstName.valid && 'validation-form'}
          />
        </div>

        <div className="input-edit-member">
          <Input
            type="text"
            name="lastName"
            value={lastName || ''}
            placeholder="Last name"
            onChange={handleChange}
            icon="lastName"
            useDivContainer
            useId="special"
            className={validationState.lastName.valid && 'validation-form'}
          />
        </div>

        <div className="input-edit-member">
          <Input
            type="text"
            name="email"
            value={email || ''}
            placeholder="Email"
            icon="email"
            onChange={handleChange}
            tooltipTitle="Email"
            useId="special"
          />
        </div>

        <div
          className={`input-edit-member ${classes.rootInputPhone}`}
          style={{ borderBottom: '2px solid #e3e3e3' }}
        >
          <InputPhone
            style={{ width: '100%', marginBottom: '5px' }}
            name="phoneNumber"
            placeholder="Phone number"
            value={phoneNumber || ''}
            onInputPhoneChange={handlePhone}
            useV3
          />
        </div>

        <div
          className={`${classes.root}`}
          id="input-edit-member"
          style={{ textAlign: 'center' }}
        >
          <BirthdayPicker value={dateValue} onChange={handleChangeBirthday} />
        </div>

        <div className="input-edit-member">
          <Input
            type="text"
            name="referenceAddress"
            placeholder="Street address"
            value={referenceAddress || ''}
            icon="street"
            onChange={handleChange}
            tooltipTitle="Street Address"
            useId="special"
          />
        </div>

        <div className="input-edit-member">
          <Input
            type="text"
            name="cityText"
            placeholder="City"
            value={cityText || ''}
            icon="city"
            onChange={handleChange}
            tooltipTitle="City"
            useId="special"
          />
        </div>

        <CountryPicker value={countryPublicId} onChange={handleCountryChange} />
        <StatePicker countryId={countryPublicId} value={statePublicId} onChange={handleStateChange} />

        <div className="input-edit-member">
          <Input
            type="text"
            name="postalCode"
            placeholder="Postal code"
            value={postalCode || ''}
            icon="postal-code"
            onChange={handleChange}
            tooltipTitle="Postal Code"
            useId="special"
          />
        </div>

        <div style={{ marginLeft: '35px', marginRight: '35px' }}>
          {pathname !== '/invited-complete-profile' ? (
            <div>
              <p className="text-member-group">Groups</p>
            </div>
          ) : null}
        </div>

        <div>
          <div id="button-send-message-2">
            <ButtonStandard title="Save" onClick={handleSubmit}/>
          </div>
        </div>

        <div>
          <div id="button-cancel-editing">
            <ButtonStandard
              title="Cancel"
              onClick={handleEditCancel}
            />
          </div>
        </div>

        {(pathname === '/invited-complete-profile' || pathname === '/my-profile') && (
          <div id="deleteAccount2" className="deleteAccount">
            <ButtonStandard title="Delete My Account " onClick={handleOpen}/>
          </div>
        )}
      </div>

      <React.Fragment>
        <Dialog
          open={isDialogVisible}
          onClose={handleClose}
          className="return-to-edit"
          maxWidth="sm"
          style={{
            backgroundColor: 'transparent',
            borderRadius: '10px',
          }}
        >
          <DialogContent className="container-warning-delete">
            <div className="warning-message">
              <div className="single-line">
                <strong> WARNING:</strong> This action will remove you from all group and
                organization communications and will delete your Evangelus account. This can not
                be undone.
                <strong> Are you sure you want to proceed?</strong>
              </div>
            </div>

            <button
              onClick={handleDeleteAccount}
              disabled={isConfirmDeleteDisabled}
              className="button-warning-decision "
            >
                <span className="text-warning">
                  Yes, unsubscribe me and delete my Evangelus account
                </span>
            </button>
            <button onClick={handleClose} className="button-warning-decision ">
              <span className="text-warning">No, I still want to receive communications</span>
            </button>
          </DialogContent>
        </Dialog>
      </React.Fragment>
    </div>
  );
}

EditMemberProfile.propTypes = EditMemberPropTypes;

const mS = state => ({
  myGroups: state.groupsReducer.globalGroups.myGroups,
  selectedGroup: state.groupsReducer.selectedGroup,
  selectedMember: state.memberReducer.selectedMember,
  error: state.memberReducer.error,
  members: state.membersReducer.membersList,
  organization: state.organizationReducer.organization.public_id,
  forceReload: state.avatarReducer.forceReload,
});

const mD = {
  enableRightDrawer,
  updateMember,
  editMember,
  editProfileUser,
  getAllGroupAsync,
  getOrgAsync,
  removeMemberFromGroup,
  updateMemberGroups,
  getMemberGroups,
  updateGroupsReducer,
  getGroupsAsync,
  snackBarStatus,
  updateDisplayChangePassword,
  displayEditMemberForm,
  deleteAccount,
  getMyGroupAsync,
  restoreSelectedMember,
};
export default withRouter(
  connect(
    mS,
    mD,
  )(withStyles(style)(EditMemberProfile)),
);
