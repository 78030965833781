import React, { Component } from 'react';
import PropTypes from 'prop-types';
import InputForSearch from '../InputForSearch';
import { FilterButton } from '../FilterButton';
import EvaTooltip from '../EvaTooltip';
import './index.sass';

class SearchFiltersPad extends Component {
  state = {
    isActive: false,
    isExpanded: false,
  }

  handleToggle = () => {
    const newState = !this.state.isActive;
    this.setState({ isActive: newState });
    this.props.onToggle(newState, this.state.isExpanded);
  }

  handleSearchCall = () => {
    this.setState({ isExpanded: true })
    this.props.onToggle(this.state.isActive, true)
  }

  handleClear = () => {
    this.setState({ isExpanded: false });
    this.props.onChange('');
    this.props.onToggle(this.state.isActive, false)
  }

  render = () => {
    const { title, isSyncButtonVisible, isFiltersSelected, onChange } = this.props;
    const { isActive, isExpanded } = this.state;
    return (
      <div className={`search-filters-pad ${isExpanded ? 'expanded' : ''} `}>
          {title && <div className="section-title">{title}
            {isSyncButtonVisible &&
              <EvaTooltip title="Sync Camino Groups">
                <button className="icon-button update-camino" onClick={this.props.onResyncCaminoGroups}/>
              </EvaTooltip>}
          </div>}

        <div className="pad-inner-wrapper">
          <InputForSearch
            clear
            name="searchTerm"
            type="text"
            placeholder="Search"
            icon="search"
            onChange={onChange}
            onClear={this.handleClear}
          />

          <div className="actions-buttons">
            <EvaTooltip title={`Search ${title}`}>
              <button className="btn-search" onClick={this.handleSearchCall}/>
            </EvaTooltip>
            <FilterButton title ={`Fitter ${title}`} isActive={isActive} isFiltersSelected={isFiltersSelected} onClick={this.handleToggle} />
          </div>
        </div>
      </div>
    )
  };
}

SearchFiltersPad.propTypes = {
  isFiltersSelected: PropTypes.bool.isRequired,
  isSyncButtonVisible: PropTypes.bool,
  title: PropTypes.string.isRequired,
  onChange: PropTypes.func,
};

export default SearchFiltersPad
