export const SORT = [
  {
    text: 'Sort',
    value: null,
  },
  {
    text: 'A-Z',
    value: 'asc',
  },
  {
    text: 'Z-A',
    value: 'desc',
  },
];

export const SORT_ACTIVE = [
  {
    text: 'Active',
    value: null,
  },
  {
    text: 'A-Z',
    value: 'asc',
  },
  {
    text: 'Z-A',
    value: 'desc',
  },
];

export const SORT_RECENT = [
  {
    text: 'Recent',
    value: null,
  },
  {
    text: 'A-Z',
    value: 'asc',
  },
  {
    text: 'Z-A',
    value: 'desc',
  },
];

export const SORT_RECENT_MESSAGES = [
  {
    text: 'Recent',
    value: 'recent',
  },
  {
    text: 'A-Z',
    value: 'asc',
  },
  {
    text: 'Z-A',
    value: 'desc',
  },
];

export const SORT_STATS = [
  {
    text: 'Past month',
    value: 'month',
  },
  {
    text: 'Past week',
    value: 'week',
  },
  {
    text: 'Custom time range',
    value: 'custom',
  },
];
