import React, { Component } from 'react';
import { connect } from 'react-redux';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import ButtonStandard from '../Button';
import {
  clearMembersList,
  clearSelectedMembers,
  getMembersToInvite,
  getMoreMembers,
  searchInMembersListWithDebounce,
  selectMember,
  updateGroupRoleMembers,
} from '../../Actions/membersActions';
import { getGroupsAsync } from '../../Actions/groupsActions';
import MembersPropTypes from '../../PropTypes/MembersPropType';
import MembersPicker from '../MembersPicker';
import PermissionsConfirmation from '../PermissionsConfirmation';
import { changeMembersSearchTerm, resetMembersFilters } from '../../Actions/membersFiltersActions';
import './index.sass';

/* eslint-disable */
class MemberAdminAdd extends Component {
  constructor(props) {
    super(props);
    this.state = { checked: false };
  }

  UNSAFE_componentWillMount() {
    const { selectedGroup } = this.props;
    this.props.clearSelectedMembers();
    this.props.getMembersToInvite([selectedGroup.public_id], '', true, 1);
  }

  componentWillUnmount() {
    this.props.clearSelectedMembers();
    this.props.clearMembersList();
    this.props.resetMembersFilters();
  }

  handleSearchTermChange = value =>
    this.props.changeMembersSearchTerm(value).then(() => {
      this.props.searchInMembersListWithDebounce();
    });

  updateGroupRoleMembers = async () => {
    const { selectedMembersHash, selectedGroup } = this.props;
    const selectedMembers = Object.keys(selectedMembersHash);
    if (this.state.checked) {
      // TODO: remove mapping
      await this.props.updateGroupRoleMembers(selectedMembers, selectedGroup.public_id,);
      this.props.handleClose();
      // Recharge groups info.
      await this.props.getGroupsAsync();
    }
  };

  handleLoadMore = () => {
    const { loading, loadingMore } = this.props;
    if (!loading && !loadingMore) {
      this.props.getMoreMembers();
    }
  }

  render() {
    const { checked } = this.state;
    const { membersList, searchTerm, selectedGroup, loading, loadingMore, selectedMembersHash, selectMember } = this.props;
    const selectedMembersCount = Object.keys(selectedMembersHash).length;

    return (
      <div id="members-admin-add">
        <div className="members-title-wrapper">
          <IconButton onClick={this.props.handleClose}>
            <KeyboardArrowLeft/>
          </IconButton>

          <div className="title-wrapper">
            <h3 className="title">Settings</h3>
            <div className="subtitle">{selectedGroup.description} Admin(s) below</div>
          </div>
        </div>

        <MembersPicker
          members={membersList}
          searchTerm={searchTerm}
          selectedMembersHash={selectedMembersHash}
          isLoading={loading}
          isLoadingMore={loadingMore}
          onSelectMember={selectMember}
          onSearchInputChange={this.handleSearchTermChange}
          onScrollFinished={this.handleLoadMore}
        />

        <div className="check-member-container">
          <div>
            <h2 style={{ fontSize: '2rem' }}>
              {selectedMembersCount} Members to be added
            </h2>
          </div>
          <PermissionsConfirmation isChecked={checked} onChange={bool => this.setState({ checked: bool })}  />
          <div className="button-create-member">
            <ButtonStandard
              title={`Add Member${(selectedMembersCount > 1) ? 's' : ''}`}
              disabled={!checked || !selectedMembersCount}
              onClick={this.updateGroupRoleMembers}
            />
          </div>
        </div>

      </div>
    );
  }
}

/* eslint-enable */

const mS = state => ({
  membersList: state.membersReducer.membersList,
  selectedMembersHash: state.membersReducer.selectedMembersHash,
  selectedGroup: state.groupsReducer.selectedGroup,
  organization: state.groupsReducer.organizations,
  loading: state.membersReducer.loading,
  loadingMore: state.membersReducer.loadingMore,

  searchTerm: state.membersFiltersReducer.searchTerm,
});

const mD = {
  changeMembersSearchTerm,
  clearMembersList,
  clearSelectedMembers,
  getGroupsAsync,
  getMembersToInvite,
  getMoreMembers,
  resetMembersFilters,
  searchInMembersListWithDebounce,
  selectMember,
  updateGroupRoleMembers,
};

MemberAdminAdd.propTypes = MembersPropTypes;
export default connect(mS, mD)(MemberAdminAdd);
