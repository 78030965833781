import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ViewMemberPropTypes from '../../PropTypes/ViewMemberPropType';
import {
  displayEditMemberForm,
  getMemberProfile,
  sendInvitation,
  setLoading,
  updateDisplayChangePassword,
} from '../../Actions/memberActions';
import { enableRightDrawer } from '../../Actions/rightDrawerShortActions';
import { enableRightDrawerMessage } from '../../Actions/rightDrawerMessageActions';
import snackBarStatus from '../../Actions/snackbarActions';
import EditMemberProfile from '../EditMemberProfile';
import EditPicture from '../EditPicture';
import { updateRecipientGroups, updateSelectedMember } from '../../Actions/messageActions';
import EvaTooltip from '../EvaTooltip';
import { openCustomModal } from '../../Actions/customModalActions';
import LoaderOverlay from '../LoaderOverlay';
import '../EditMember/index.sass';
import MemberInfoPad from './../MemberInfoPad';
import MessageModalManager, { MESSAGE_MODAL_MANAGER_VIEWS } from '../../Containers/MessageModalManager';

/**
 * @Component EditMemberProfile/ViewMember
 */
class ViewMemberProfile extends Component {
  state = {
    displayEdit: false,
    displayPicture: false,
  };
  handleEdit = e => {
    e.preventDefault();
    this.props.updateDisplayChangePassword(false);
    if (this.props.location.pathname === '/') {
      this.props.enableRightDrawer({
        payload: {
          enable: true,
          element: <EditMemberProfile />,
          title: '',
        },
      });
    } else {
      this.setState({ displayEdit: true });
      this.props.displayEditMemberForm(true);
    }
  };

  changeDisplay = ({ displayEdit, displayPicture }) => {
    // this.props.getMemberProfile(); // TODO: verify
    this.setState({ displayEdit, displayPicture });
    if (!displayEdit && !displayPicture) {
      this.props.displayEditMemberForm(false);
      this.props.updateDisplayChangePassword(true);
    }
  };

  switchEditable = changeDisplay => {
    if (this.state.displayPicture) {
      return (
        <EditPicture
          changeDisplay={changeDisplay}
          group={this.props.selectedGroup}
          member={this.props.selectedMember}
        />
      );
    }
    return <EditMemberProfile changeDisplay={changeDisplay} />;
  };

  handleLink = () => {
    const { selectedGroup, selectedMember } = this.props;
    this.props.setLoading(true);
    this.props
      .sendInvitation(selectedGroup.public_id, selectedMember.publicId)
      .then(res => {
        if (res.status === 200) {
          this.props.snackBarStatus({
            payload: {
              enable: true,
              title: 'Message Sent',
              type: 'success',
            },
          });
          this.props.setLoading(false);
        }
      })
      .catch(() => {
        this.props.snackBarStatus({
          payload: {
            enable: true,
            title: 'Error, try again',
            type: 'error',
          },
        });
      });
  };

  handleSendDirectMessageAction = () => {
    const { selectedMember, selectedGroup } = this.props;

    this.props.initDirectMessage(selectedMember.publicId, selectedGroup.public_id);

    this.props.openCustomModal({ content: <MessageModalManager view={MESSAGE_MODAL_MANAGER_VIEWS.EDITOR}/> });

    this.props.enableRightDrawer({
      payload: {
        enable: false,
        element: <div />,
        title: '',
      },
    });
  };

  render() {
    const {
      selectedMember,
      loading,
    } = this.props;
    // const isInvitedCompletePage = this.props.location.pathname === '/invited-complete-profile';

    return (
      <React.Fragment>
        {this.state.displayEdit || this.state.displayPicture ? (
          <div>{this.switchEditable(this.changeDisplay)}</div>
        ) : (
          <section className="view-member-wrapper view-mode" id="view-member-form">

            <div className="edit-member-create-icon">
              <EvaTooltip title="Edit Profile">
                <button onClick={this.handleEdit}/>
              </EvaTooltip>
            </div>

            <MemberInfoPad
              isSelf
              isShowPasswordField
              member={selectedMember}
              onDirectClick={this.handleSendDirectMessageAction}
              onShareClick={this.handleLink}
            />

            {loading && <LoaderOverlay />}

            {/*<section>*/}
            {/*  {!isInvitedCompletePage && (*/}
            {/*    <div className="section-title">Groups</div>*/}
            {/*  )}*/}
              {/*<GroupsChannelsList groups={membersDataUpdated || memberGroups}/>*/}
            {/*</section>*/}
          </section>
        )}
      </React.Fragment>
    );
  }
}

ViewMemberProfile.propTypes = ViewMemberPropTypes;

const mS = state => ({
  groups: state.groupsReducer.groups,
  loading: state.memberReducer.loading,
  selectedMember: state.memberReducer.selectedMember,
  error: state.groupsReducer.error,
  globalGroups: state.groupsReducer.globalGroups,
  memberGroups: state.memberReducer.selectedMember.groups,
  userGroups: state.userProfileReducer.member.groups,
  selectedGroup: state.groupsReducer.selectedGroup,
});

const mD = {
  enableRightDrawer,
  getMemberProfile,
  snackBarStatus,
  sendInvitation,
  setLoading,
  enableRightDrawerMessage,
  updateSelectedMember,
  updateRecipientGroups,
  updateDisplayChangePassword,
  openCustomModal,
  displayEditMemberForm,
  // navigationControllerRedirect,
};
export default withRouter(
  connect(
    mS,
    mD,
  )(ViewMemberProfile),
);
