import Notifications from '../Api/Notifications';
import snackBarStatus from './snackbarActions';

export const ACTIONS = {
  ACCEPT_JOIN_REQUEST: '[Notifications] Accept join',
  DISMISS_NOTIFICATION: '[Notifications] Dismiss',
  LOAD_NOTIFICATIONS: '[Notifications] Load',
  LOADING_NOTIFICATIONS: '[Notifications] Is loading',
  REMOVING_NOTIFICATIONS: '[Notifications] Is removing',
  REMOVE_NOTIFICATION: '[Notifications] Remove',
  CLEAR_NOTIFICATIONS: '[Notifications] Clear',
};

const setLoading = (bool) => dispatch => {
  dispatch({
    type: ACTIONS.LOADING_NOTIFICATIONS,
    payload: bool,
  });
}

const setRemoving = (bool) => dispatch => {
  dispatch({
    type: ACTIONS.REMOVING_NOTIFICATIONS,
    payload: bool,
  });
}

export const getNotificationsMessages = (offset = 0, limit = 50) => async (dispatch, getState) => {

  const { notificationsReducer: { isLoading } } = getState();
  const { organizationReducer: { organization: { public_id } } } = getState();

  if (isLoading) {
    return;
  }

  try {
    setLoading(true)(dispatch);

    const response = await Notifications.getNotifications(offset, limit, public_id);
    if (response.status === 200) {
      const {
        data: {
          data: { data, total },
        },
      } = response;

      const notifications = data;
      const isLastChunk = total <= notifications.length + offset;

      dispatch({
        type: ACTIONS.LOAD_NOTIFICATIONS,
        payload: { notifications, total, isLastChunk },
      });

      setLoading(false)(dispatch);
    }
    return response;
  } catch (error) {
    setLoading(false)(dispatch);
    snackBarStatus({
      payload: {
        title: error.message,
        type: 'error',
        enable: true,
      },
    })(dispatch);
    return error;
  }
};

export const dismissNotifications = notificationId => async dispatch => {
  try {
    const notifications = { notifications: [notificationId] };
    const response = await Notifications.dismissNotification(notifications);
    if (response.status === 200) {
      dispatch({
        type: ACTIONS.DISMISS_NOTIFICATION,
        payload: notificationId,
      });
    }
    return response;
  } catch (error) {
    snackBarStatus({
      payload: {
        title: error.message,
        type: 'error',
        enable: true,
      },
    })(dispatch);
    return error;
  }
};

export const acceptJoinRequest = data => async dispatch => {
  try {
    const { groupId, memberId } = data;
    const response = await Notifications.acceptJoinRequest({ groupId, memberId });
    if (response.status === 200) {
      dispatch({
        type: ACTIONS.ACCEPT_JOIN_REQUEST,
        payload: data.notificationId,
      });
    }
    return response;
  } catch (error) {
    if (error.code === 403) {
      dispatch({
        type: ACTIONS.REMOVE_NOTIFICATION,
        payload: data.notificationId,
      });
    } else {
      snackBarStatus({
        payload: {
          title: error.message,
          type: 'error',
          enable: true,
        },
      })(dispatch);
    }
    return error;
  }
};

export const denyJoinRequest = data => async dispatch => {
  try {
    const { groupId, memberId } = data;
    const response = await Notifications.denyJoinRequest({ groupId, memberId });
    if (response.status === 200) {
      dispatch({
        type: ACTIONS.ACCEPT_JOIN_REQUEST,
        payload: data.notificationId,
      });
    }
    return response;
  } catch (error) {
    if (error.code === 403) {
      dispatch({
        type: ACTIONS.REMOVE_NOTIFICATION,
        payload: data.notificationId,
      });
    } else {
      snackBarStatus({
        payload: {
          title: error.message,
          type: 'error',
          enable: true,
        },
      })(dispatch);
    }
    return error;
  }
};

export const clearNotifications = () => async (dispatch, getState) => {
  const { organizationReducer: { organization: { public_id } } } = getState();
  setRemoving(true)(dispatch);
  try {
    const response = await Notifications.clearNotifications(public_id);
    if (response.status === 200) {
      dispatch({
        type: ACTIONS.CLEAR_NOTIFICATIONS
      });
      await getNotificationsMessages()(dispatch, getState);
    }
    setRemoving(false)(dispatch);
    return response;
  } catch (error) {
    setRemoving(false)(dispatch);
    snackBarStatus({
      payload: {
        title: error.message,
        type: 'error',
        enable: true,
      },
    })(dispatch);
    return error;
  }
}
