import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Scrollbars } from 'react-custom-scrollbars';
import organizationImage from '../../Theme/Icons/org-icon.svg';
import { requestGroupAction, requestOrganizationAction } from '../../Actions/invitationActions';
import { navigationControllerRedirect } from '../../Actions/navigationControllerActions';
import InviteScreen from '../../Components/InviteScreen';
import { logout } from '../../Actions/loginActions';
import { redirectToLogin } from '../../Helpers/redirectToLogin';
import getMemberInfo from '../../Helpers/getMemberInfo';
import { camelCaseObjects } from '../../Helpers/Utility';
import { injectIntl } from 'react-intl';

class Invitation extends Component {

  constructor(props) {
    super(props);
    this.state = { isInvitedUserLogged: false };
  }

  async componentDidMount() {
    const { type, publicId, invitationToken } = this.props.match.params;
    if (type === 'organization') {
      this.props.requestOrganizationAction(publicId, () => {
        this.props.history.push('/not-found');
      });
    } else if (type === 'group') {
      if (invitationToken && invitationToken.length > 0) {
        this.invitationToken = invitationToken;
      }
      this.props.requestGroupAction(publicId, () => {
        this.props.history.push('/not-found');
      });
    } else {
      this.props.history.push('/not-found');
    }
  }

  static getDerivedStateFromProps = nextProps => ({
    isInvitedUserLogged: !!nextProps.loggedMember.public_id
  });

  handleLogin = () => redirectToLogin(this.props.location.pathname);

  handleLogout = () => this.props.logout(this.props.location.pathname);

  handleJoin = () => {
    const { type, publicId } = this.props.match.params;
    this.props.navigationControllerRedirect(`/invitation-submit/${type}/${publicId}`);
  };

  handleRegister = () => {
    const {
      match: {
        params: { type, publicId, invitationToken },
      },
      organization,
    } = this.props;

    let id = '';

    switch (type) {
      case 'group':
        id = publicId;
        break;
      case 'organization':
        id = organization.all_members_group.public_id;
        break;
    }

    let url = `/complete-profile/${id}`;

    if (invitationToken) {
      url += `/${invitationToken}`
    }

    this.props.navigationControllerRedirect(url);
  };

  render() {
    const { organization, group, loggedMember } = this.props;
    let displayedName = null;
    if (loggedMember && loggedMember.public_id) {
      displayedName = getMemberInfo(camelCaseObjects(loggedMember)).displayedFullName;
    }
    const {
      match: {
        params: { type },
      },
      intl,
    } = this.props;
    let titleName = group && group.name ? group.name : '';
    let image = group && group.main_picture_url ? group.main_picture_url : organizationImage;
    const subtitleName = group && group.organization ? group.organization.name : '';

    if (type === 'organization') {
      titleName = organization.name;
      image = organization.main_picture_url ? organization.main_picture_url : organizationImage;
    }

    return (
      <Scrollbars autoHeight autoHeightMin="100vh">
        <InviteScreen
          isLanguageSwitchVisible
          title={titleName}
          subtitle={subtitleName}
          pictureUrl={image}
          type={type}
          inviteTextHtml={intl.formatMessage({ id: 'text.invitedToReceiveCommunications' })}
          descriptionTextHtml={intl.formatMessage({ id: 'text.joinOrRegister' }, { type })}
          registerButtonText={intl.formatMessage({ id: 'action.registerToJoin' })}
          loginButtonText={intl.formatMessage({ id: 'action.loginAndJoin' })}
          userName={displayedName}
          isInvitedUserLogged={this.state.isInvitedUserLogged}
          isActiveUser={loggedMember.status === 'active'}
          onRegister={this.handleRegister}
          onJoin={this.handleJoin}
          onLogin={this.handleLogin}
          onLogout={this.handleLogout}
        />
      </Scrollbars>
    );
  }
}

const mS = state => ({
  organization: state.invitationReducer.organization,
  group: state.invitationReducer.group,
  loggedMember: state.userProfileReducer.member,
});

const mD = {
  requestOrganizationAction,
  requestGroupAction,
  navigationControllerRedirect,
  logout,
};

export default connect(
  mS,
  mD,
)(injectIntl(Invitation));
