// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../Theme/Icons/active.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".member-item{padding-right:10px}.member-item.member-clickable{cursor:pointer}.member-item .member-full-name{font-family:\"GothamSSm-Book\",Arial,sans-serif;font-size:16px;color:#4a4a4a}.member-item .member-full-name b{font-family:\"GothamSSm-Bold\",Arial,sans-serif}.member-item .pseudo-checkbox i{display:block;width:20px;height:20px;background-color:#e3e3e3;border-radius:5px}.member-item .pseudo-checkbox.active i{background:transparent url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") -12px -10px}.member-item.list-view{display:-ms-flexbox;display:flex;-ms-flex-align:center;align-items:center}.member-item.list-view .pseudo-checkbox{padding:20px 7px 20px 0;margin-right:5px}.member-item.list-view .member-avatar{margin-right:10px;width:40px;height:40px}.member-item.list-view .member-full-name{min-height:60px;display:-ms-flexbox;display:flex;-ms-flex-align:center;align-items:center;white-space:nowrap;overflow:hidden;text-overflow:ellipsis}.member-item.list-view .member-full-name span{margin-right:1ex}.member-item.grid-view{padding:0 5px 10px;-webkit-box-sizing:border-box;box-sizing:border-box;position:relative;text-align:center}.member-item.grid-view .pseudo-checkbox{position:absolute;left:0;top:0;padding:0 20px 20px 0}.member-item.grid-view .member-avatar{margin:0 auto 10px;width:100px;height:100px}.member-item.grid-view .member-full-name{-ms-flex-align:center;align-items:center}.member-item.grid-view .member-full-name span,.member-item.grid-view .member-full-name b{display:block;overflow:hidden;white-space:nowrap;text-overflow:ellipsis}\n", ""]);
// Exports
module.exports = exports;
