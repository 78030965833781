// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../Theme/Icons/email.svg");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../Theme/Icons/phone.svg");
var ___CSS_LOADER_URL_IMPORT_2___ = require("../../Theme/Icons/birthday.svg");
var ___CSS_LOADER_URL_IMPORT_3___ = require("../../Theme/Icons/location.svg");
var ___CSS_LOADER_URL_IMPORT_4___ = require("../../Theme/Icons/password.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
// Module
exports.push([module.id, ".member-info-pad .member-picture-view{margin-bottom:20px}.member-info-pad .member-info-fields{margin-bottom:30px}.member-info-pad .member-info-fields .field-item{padding:2px 0 2px 50px;min-height:24px;-webkit-box-sizing:border-box;box-sizing:border-box;background:no-repeat left center;overflow:hidden;text-overflow:ellipsis;font-family:\"GothamSSm-Medium\",Arial,sans-serif;font-size:14px;color:#4a4a4a}.member-info-pad .member-info-fields .field-item:not(:last-child){margin-bottom:15px}.member-info-pad .member-info-fields .field-item.field-email{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-position-x:-6px}.member-info-pad .member-info-fields .field-item.field-phone{display:-ms-flexbox;display:flex;-ms-flex-align:center;align-items:center;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");background-position-x:-5px}.member-info-pad .member-info-fields .field-item.field-phone img{margin-right:10px;height:20px;border-radius:4px}.member-info-pad .member-info-fields .field-item.field-birthday{background-size:25px;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ");background-position-x:3px}.member-info-pad .member-info-fields .field-item.field-address{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ");background-position-x:-4px}.member-info-pad .member-info-fields .field-item.field-address>div{overflow:hidden;text-overflow:ellipsis}.member-info-pad .member-info-fields .field-item.field-password{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_4___ + ");background-position-x:-3px;font-size:20px;line-height:1;padding-top:4px;padding-bottom:0}.member-info-pad .member-info-actions .button-wrapper{text-align:center}.member-info-pad .member-info-actions .button-wrapper:not(:last-child){margin-bottom:10px}.member-info-pad .member-info-actions .button-wrapper .button-share-link{padding:10px;font-family:\"GothamSSm-Book\",Arial,sans-serif;font-size:16px;color:#9a66bf}\n", ""]);
// Exports
module.exports = exports;
