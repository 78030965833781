import React, { Component } from 'react';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import { Scrollbars } from 'react-custom-scrollbars';
import {
  clearFormGroup,
  clearSearchedGroups,
  searchGroups,
  selectGroup,
  updateMiddleMenu
} from '../../Actions/groupsActions';
import { archiveMessages, getPendingReplies } from '../../Actions/messagesActions';
import { enableRightDrawer } from '../../Actions/rightDrawerActions';
import { enableRightDrawerMessage } from '../../Actions/rightDrawerMessageActions';
import { changeTab, OPTIONS } from '../../Actions/middleMenuActions';
import GroupCard from '../../Components/GroupCard';
import MiddleMenu from '../../Components/MiddleMenu';
import snackBarStatus from '../../Actions/snackbarActions';
import MainMenu from '../../Components/MainMenu';
import GroupsComponent from '../../Components/Groups';
import { FB_APP_ID, FB_GRAPH_VERSION } from '../../Config/env';
import { clearMessage, updateRecipientGroups } from '../../Actions/messageActions';
import { getUserProfile } from '../../Actions/userActions';
import ChangeMainTabs from '../../Actions/mainTabsActions';
import { clearCompose } from '../../Actions/composeActions';
import MemberList from '../../Components/MemberList';
import EditGroup from './EditGroup';
import CustomRightHeader from '../../Components/CustomRightHeader';
import { openCustomModal } from '../../Actions/customModalActions';
import MessageSelectedContainer from '../MessageSelectedContainer';
import LoaderOverlay from '../../Components/LoaderOverlay';
import { updateImportMessage } from '../../Actions/membersActions';
import SearchFiltersPad from '../../Components/SearchFiltersPad';
import {
  changeGroupsFilters,
  changeGroupsSearchTerm,
  changeGroupsSort,
  resetGroupsFilters,
  toggleGroupsFilters,
} from '../../Actions/groupsFiltersActions';
import { getNotificationsMessages } from '../../Actions/notificationsActions';
import GroupsFilters from '../../Components/GroupsFilters';
import ButtonSelect from '../../Components/ButtonSelect';
import { ROLE_EVA_GROUP_ADMIN, SORT_ACTIVE } from '../../Config/Constants';
import CreateGroups from './CreateGroups';
import MessageModalManager, { MESSAGE_MODAL_MANAGER_VIEWS } from '../MessageModalManager';
import { resetMembersFilters } from '../../Actions/membersFiltersActions';
import { debounce } from '../../Helpers/debounce';
import { resyncCaminoGroups } from '../../Actions/organizationActions';
import './index.sass';

/* eslint-disable*/
class Groups extends Component {
  constructor(props) {
    super(props);
    this.state = {
      charactersError: 'Please provide at least two characters to search',
      searchIconAnimation: false,
      screenOrientation: 'portrait',
      lastPage: false,
    };

    this.middleMenuRef = React.createRef();
    this.middleMenuMobileRef = React.createRef();


    /* eslint-disable */
    window.fbAsyncInit = function () {
      FB.init({
        appId: FB_APP_ID,
        cookie: true,
        xfbml: true,
        version: FB_GRAPH_VERSION,
      });

      FB.AppEvents.logPageView();
    };

    (function (d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        return;
      }
      js = d.createElement(s);
      js.id = id;
      js.src = 'https://connect.facebook.net/en_US/sdk.js';
      fjs.parentNode.insertBefore(js, fjs);
    })(document, 'script', 'facebook-jssdk');
    /* eslint-enable */
  }

  componentDidMount() {
    this.props.selectGroup({});
    this.props.getPendingReplies();

    this.props.changeTab(<div/>, 10);

    this.loadGroups();

    localStorage.removeItem('statusDesignerRedirect');
    this.props.ChangeMainTabs({ payload: { value: 0 } });
    this.getScreenOrientation();
    window.addEventListener('orientationchange', this.getScreenOrientation);
    this.debouncedSearch = debounce(this.loadGroups, 800);

    this.props.getNotificationsMessages();
  }

  // TODO: unify
  componentWillUnmount() {
    this.props.clearSearchedGroups();
    this.props.updateMiddleMenu(false);
    window.removeEventListener('orientationchange', this.getScreenOrientation);
    this.props.resetGroupsFilters();
  }

  // debouncer is not strictly needed, but this function
  // needs to be delayed for atleast the next tick so render
  // is called again later and window.innerHeight is calculated correctly
  getScreenOrientation = debounce(() => {
    const orientation =
      window.screen.msOrientation ||
      window.screen.mozOrientation ||
      (window.screen.orientation || {}).type;
    if (orientation === 'portrait-secondary' || orientation === 'portrait-primary') {
      this.setState({ screenOrientation: 'portrait' });
    } else {
      this.setState({ screenOrientation: 'landscape' });
    }
  }, 100);

  debouncedSearch = () => {
  };

  displayNewMessage = (event, group) => {
    event.preventDefault();
    this.props.selectGroup(group);
    this.props.clearFormGroup();
    this.props.clearCompose();
    this.props.clearMessage();

    this.props.updateRecipientGroups([group.public_id]);

    this.props.openCustomModal({
      content: <MessageModalManager view={MESSAGE_MODAL_MANAGER_VIEWS.EDITOR} onSend={this.search} />
    });
  };

  handleGroupSelect = (event, group, isAddMember = false) => {
    event.preventDefault();
    this.props.resetMembersFilters();
    this.props.selectGroup(group);
    this.props.clearFormGroup();
    this.props.updateMiddleMenu(true);
    if (group.isEnabled) {
      // this.props.updateStatusGroup('');
      if (group.members_quantity > 0) {
        this.props.changeTab(<MemberList afterMembersAction={this.search}/>, 1);
      } else {
        this.props.changeTab(<GroupsComponent/>, 1);
      }
    } else {
      // this.props.updateStatusGroup('disable-view');
      this.props.changeTab(<EditGroup afterMembersAction={this.search} {...this.props} />, 4);
    }
    this.props.clearMessage();
    this.props.updateRecipientGroups([]);

    if (window.innerWidth <= 968) {
      let profilePicture;
      if (
        this.props.member &&
        this.props.member.profile_picture_url
      ) {
        profilePicture = this.props.member.profile_picture_url;
      }
      this.props.enableRightDrawerMessage({
        payload: {
          enable: true,
          element: (
            <MiddleMenu
              {...this.props}
              ref={this.middleMenuMobileRef}
              isAddMember={isAddMember}
              afterMembersAction={this.loadGroups()}
            />
          ),
          title: 'Groups',
          disableEnforceFocus: true,
          secondTitle: <CustomRightHeader memberImage={profilePicture}/>,
        },
      });
    }
    this.props.updateImportMessage({
      show: false,
      message: '',
    });
  };

  handleGetMoreGroups() {
    const { pagination: { currentPage, nextPage, totalPages } } = this.props;

    if (currentPage < totalPages) {
      this.loadGroups(nextPage)
    }
  }

  loadGroups = (page = 1) => {
    const {
      sort,
      pagination: { currentPage },
      searchTerm,
      filters: { privacyLevel, activity, shareOptions, groupType }
    } = this.props;

    this.props.searchGroups({
      sort,
      searchTerm,
      privacyLevel,
      activity,
      shareOptions,
      groupType,
      page: page ? page : currentPage
    });
  };

  handleFiltersChange = filters => {
    this.props.changeGroupsFilters(filters)
      .then(this.debouncedSearch);
  };

  handleSearchTermChange = (str) => {
    this.props.changeGroupsSearchTerm(str)
      .then(this.debouncedSearch);
  };

  handleSort = value => {
    this.props.changeGroupsSort(value)
      .then(this.debouncedSearch);
  };

  handleArchive = publicIds => this.props.archiveMessages(publicIds).then(this.loadMessages);

  handleSettingsClick = (e, group) => {
    this.handleGroupSelect(e, group)

    const ref = window.innerWidth <= 968 ? this.middleMenuMobileRef : this.middleMenuRef;
    ref.current.handleChange(e, OPTIONS.SETTINGS_OPTION);
  }

  renderGroupCard = (g, parentGroupName) => (
    <>
      <GroupCard
        key={g.public_id}
        isSelected={this.props.selectedGroup?.public_id === g.public_id}
        data={g}
        parentName={parentGroupName}
        onNewMessage={this.displayNewMessage}
        onSelect={this.handleGroupSelect}
        onAddMember={this.search}
        onSettingsClick={this.handleSettingsClick}
      />
      {g.subGroups?.length > 0 && (
        <div className="nested-wrapper">
          {g.subGroups.map((subGroup) =>
            this.renderGroupCard(subGroup, parentGroupName ? `${parentGroupName} / ${g.name}` : g.name))
          }
        </div>
      )}
    </>
  )

  resyncCaminoGroups = () => {
    this.props.resyncCaminoGroups(this.props.orgId)
  }

  render() {
    const WindowHeight =
      this.state.screenOrientation === 'portrait'
        ? window.innerHeight - 80
        : window.innerHeight - 130;

    const {
      groups,
      middleMenuStatus,
      middleMenuLeft: { status, dinamicGrid, dinamicGridMiddleMenu, messageId },
      isLoadingNewGroups,
      isLoadingMoreGroups,
      isFiltersOpen,
      filters,
      role,
      linkToCamino,
    } = this.props;
    const isAnyFilterSelected = filters.activity.length > 0 ||
      filters.privacyLevel.length > 0 || filters.shareOptions.length > 0;

    return (
      <MainMenu
        value={0}
        content={
          <Grid container spacing={0} className="global-group-container">
            <Grid id="groups-container" item xs={12} sm={12} md={5} lg={5}>
              <Scrollbars
                autoHeight
                autoHide
                autoHeightMin={WindowHeight - 35}
                autoHeightMax={WindowHeight - 35}
                onUpdate={({ top }) => {
                  if (top > 0.99 && !isLoadingNewGroups && !isLoadingMoreGroups && !this.state.lastPage) {
                    this.handleGetMoreGroups();
                  }
                }}
                className={`${!middleMenuStatus && 'no-middle-menu'} custom-scrollbar`}
              >
                <div className="filters-column">
                  <SearchFiltersPad
                    isFiltersSelected={isAnyFilterSelected}
                    isSyncButtonVisible={!!linkToCamino}
                    title="Groups"
                    onChange={this.handleSearchTermChange}
                    onToggle={this.props.toggleGroupsFilters}
                    onResyncCaminoGroups={this.resyncCaminoGroups}
                  />

                  <GroupsFilters
                    filters={filters}
                    isOpen={isFiltersOpen}
                    onFiltersChange={this.handleFiltersChange}
                  />

                  <div className="actions-sort-pad">
                    <ButtonSelect className="button-sort" values={SORT_ACTIVE} defaultValue={null}
                                  onChange={this.handleSort}/>
                    {role !== ROLE_EVA_GROUP_ADMIN && <CreateGroups/>}
                  </div>

                  <div className="group-cards-list">
                    {(!isLoadingNewGroups || isLoadingMoreGroups) && groups.map((g) => this.renderGroupCard(g, ''))}
                    {(isLoadingNewGroups || isLoadingMoreGroups) && <LoaderOverlay/>}
                  </div>
                </div>
              </Scrollbars>
            </Grid>

            <Hidden smDown>
              <Grid
                item
                xs={12}
                sm={dinamicGrid}
                md={dinamicGrid}
                lg={dinamicGrid}
                className={status ? status : ''}
              >
                <Grid container>
                  <Grid
                    item
                    xs={12}
                    sm={dinamicGridMiddleMenu}
                    md={dinamicGridMiddleMenu}
                    lg={dinamicGridMiddleMenu}
                  >
                    <MiddleMenu {...this.props} ref={this.middleMenuRef} afterMembersAction={this.search}/>
                  </Grid>
                  {status && (
                    /* eslint-disable */
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={6}
                      lg={6}
                      id="groups-container-left"
                      className="new-left"
                    >
                      <Scrollbars
                        autoHeight
                        autoHide
                        autoHeightMin={WindowHeight}
                        autoHeightMax={WindowHeight}
                      >
                        <MessageSelectedContainer messageId={messageId} onArchive={this.handleArchive}/>
                      </Scrollbars>
                    </Grid>
                    /* eslint-enable */
                  )}
                </Grid>
              </Grid>
            </Hidden>

            <div id="modal-confirm"/>
          </Grid>
        }
      />
    );
  }
}

const mS = state => ({
  groups: state.groupsReducer.groups,
  selectedGroup: state.groupsReducer.selectedGroup,
  searchedGroups: state.groupsReducer.searchedGroups,
  pagination: state.groupsReducer.pagination,
  middleMenuStatus: state.groupsReducer.middleMenuStatus,
  middleMenuLeft: state.groupsReducer.middleMenuLeft,
  member: state.userProfileReducer.member,
  isLoadingNewGroups: state.groupsReducer.isLoadingNewGroups,
  isLoadingMoreGroups: state.groupsReducer.isLoadingMoreGroups,
  messages: state.messagesReducer.messages,
  isFiltersOpen: state.groupsFiltersReducer.isFiltersOpen,

  filters: state.groupsFiltersReducer.filters,
  sort: state.groupsFiltersReducer.sort,
  searchTerm: state.groupsFiltersReducer.searchTerm,

  isLoaded: state.notificationsReducer.isLoaded,
  isLoading: state.notificationsReducer.isLoading,
  role: state.userProfileReducer.main_role,
  orgId: state.organizationReducer.organization.public_id,
  linkToCamino: state.organizationReducer.organization.link_to_camino
});

const mD = {
  enableRightDrawer,
  selectGroup,
  clearFormGroup,
  changeTab,
  searchGroups,
  clearSearchedGroups,
  snackBarStatus,
  enableRightDrawerMessage,
  updateRecipientGroups,
  getUserProfile,
  ChangeMainTabs,
  updateMiddleMenu,
  openCustomModal,
  getPendingReplies,
  clearCompose,
  updateImportMessage,
  clearMessage,
  resetGroupsFilters,
  toggleGroupsFilters,
  changeGroupsFilters,
  changeGroupsSort,
  changeGroupsSearchTerm,
  getNotificationsMessages,
  archiveMessages,
  resetMembersFilters,
  resyncCaminoGroups,
};

export default connect(mS, mD)(Groups);
