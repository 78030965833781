import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Scrollbars } from 'react-custom-scrollbars';
import { withRouter } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { requestGroupAction } from '../../Actions/invitationActions';
import {
  createUserProfile,
  getUserAndGroupInformation,
  updateData,
  updateUserProfile,
} from '../../Actions/completeProfileActions';
import { getStates } from '../../Actions/statesDictionaryActions';
import snackBarStatus from '../../Actions/snackbarActions';
import SnackBar from '../Snackbar';
import memberImg from '../../Theme/Icons/member.svg';
import { redirectToLogin } from '../../Helpers/redirectToLogin';
import { InputIconGridless } from '../InputIcon';
import { InputPhone } from '../InputPhone';
import moment from 'moment';
import { getDateToday } from '../../Helpers/date';
import Helpers from '../../Helpers';
import { PASSWORD_REQUIREMENTS } from '../../Helpers/reduxFormValidators';
import ReactHtmlParser from 'react-html-parser';
import { Checkbox } from '@material-ui/core';
import SimpleModal from '../SimpleModal';
import DummyContent from './contentPrivacyPolicy';
import LoadingScreen from '../../Components/LoadingScreen';
import ButtonSimple from '../ButtonSimple';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import BirthdayPicker from '../BirthdayPicker';
import StatePicker from '../StatePicker';
import CountryPicker from '../CountryPicker';
import './index.sass';
import LanguageSwitcher from '../LanguageSwitcher';

// Erase auth before.
class CompleteProfile extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      fieldsValidation: {
        phone: true,
        password: true,
        passwordConfirmation: true,
      },
      isModalOpen: false,
      password: '',
      passwordConfirmation: '',
      passwordValidation: {
        lowercase: false,
        uppercase: false,
        specialCharacterOrNumber: false,
        length: false,
        passwordsMatch: false,
      },
      privacyPolicyAccepted: false,
      validationErrors: {
        email: [],
        phone: [],
        password: [],
        passwordConfirmation: [],
        contactPreference: [],
        privacyPolicy: [],
      },
    }
  }

  componentDidMount() {
    const { groupId, token } = this.props.match.params;

    if (groupId) {
      this.props.requestGroupAction(groupId);
    }

    if (groupId && token) {
      this.props.getUserAndGroupInformation(groupId, token);
    }

    this.props.getStates();
  }

  updateEmailValidation = async () => {
    const { email } = this.props;
    const validationErrors = { ...this.state.validationErrors, email: [] };
    const fieldsValidation = { ...this.state.fieldsValidation, email: true };

    if (!email || email.length === 0) {
      validationErrors.email.push('Invalid email');
      fieldsValidation.email = false;
    }

    if (email && email.length > 0 && !Helpers.EmailSimple(email)) {
      validationErrors.email.push('Invalid email');
      fieldsValidation.email = false;
    }

    return new Promise((resolve) => this.setState({ fieldsValidation, validationErrors }, resolve));
  };

  updatePhoneValidation = async () => {
    const { phoneNumber } = this.props;
    const validationErrors = { ...this.state.validationErrors, phone: [] };
    const fieldsValidation = { ...this.state.fieldsValidation, phone: true };

    if (phoneNumber && phoneNumber.length) {
      const parsedNumber = parsePhoneNumberFromString(phoneNumber);
      const isValidPhone = parsedNumber && parsedNumber.isValid();
      fieldsValidation.phone = isValidPhone;
      if (!isValidPhone) {
        validationErrors.phone.push('Phone is incorrect');
      }
    }

    return new Promise((resolve) => this.setState({ fieldsValidation, validationErrors }, resolve));
  };

  updatePasswordValidation = async () => {
    const { password, passwordValidation } = this.state;

    const validationErrors = { ...this.state.validationErrors, password: [] };
    const fieldsValidation = { ...this.state.fieldsValidation, password: true };

    if (password.length === 0) {
      validationErrors.password.push('Password is required');
      fieldsValidation.password = false;
    } else {
      const { lowercase, uppercase, specialCharacterOrNumber } = passwordValidation;
      if (!lowercase || !uppercase || !specialCharacterOrNumber) {
        validationErrors.password.push('Your password must meet all the requirements listed below.');
        fieldsValidation.password = false;
      }
    }

    return new Promise((resolve) => this.setState({ fieldsValidation, validationErrors }, resolve));
  };

  updateConfirmationPasswordValidation = async () => {
    const { password, passwordConfirmation } = this.state;

    const validationErrors = { ...this.state.validationErrors, passwordConfirmation: [] };
    const fieldsValidation = { ...this.state.fieldsValidation, passwordConfirmation: true };

    if (passwordConfirmation.length === 0) {
      validationErrors.passwordConfirmation.push('Password confirmation is required');
      fieldsValidation.passwordConfirmation = false;
    }

    if (passwordConfirmation.length > 0 && password !== passwordConfirmation) {
      validationErrors.passwordConfirmation.push('Confirmation password must match.');
      fieldsValidation.passwordConfirmation = false;
    }

    return new Promise((resolve) => this.setState({ fieldsValidation, validationErrors }, resolve));
  };

  updateContactPreferenceValidation = async () => {
    const { isLiteMode, email, phoneNumber, contactPreference } = this.props;

    const validationErrors = { ...this.state.validationErrors, contactPreference: [] };

    if (!isLiteMode && contactPreference !== 'sms' && contactPreference !== 'email') {
      validationErrors.contactPreference.push('Please select your communication preference below.');
    }

    if (contactPreference === 'email' && !email) {
      validationErrors.contactPreference.push('Please enter email first to be able to select communication preference by email');
    }

    if (contactPreference === 'sms' && !phoneNumber) {
      validationErrors.contactPreference.push('Please enter phone number first to be able to select communication preference by sms');
    }

    return new Promise((resolve) => this.setState({ validationErrors }, resolve));
  };

  updatePrivacyPolicyValidation = async () => {
    const validationErrors = { ...this.state.validationErrors, privacyPolicy: [] };

    if (!this.state.privacyPolicyAccepted) {
      validationErrors.privacyPolicy.push('You must review and accept the Privacy Policy before continuing.');
    }

    return new Promise((resolve) => this.setState({ validationErrors }, resolve));
  };

  updateValidation = async () => {
    const validators = [
      this.updateEmailValidation,
      this.updatePhoneValidation,
      this.updatePasswordValidation,
      this.updateConfirmationPasswordValidation,
      this.updateContactPreferenceValidation,
      this.updatePrivacyPolicyValidation
    ];

    for (let i = 0; i < validators.length; i++) {
      await validators[i]();
    }
  };

  submit = async () => {
    await this.updateValidation();

    const errors = Object.values(this.state.validationErrors).flat();

    if (errors.length) {
      this.props.snackBarStatus({
        payload: {
          title: errors,
          enable: true,
          type: 'formError',
        },
      });
    } else {
      const { token } = this.props.match.params;
      const { password } = this.state;

      const response = token ? await this.props.updateUserProfile(password, token) : await this.props.createUserProfile(password);

      if (response.status === 200) {
        redirectToLogin();
      }
    }
  };

  handleChange = (e, attr) => {
    this.props.updateData({ [`${attr}`]: e.target.value });
    if (attr === 'email') {
      this.updateEmailValidation();
    }
  };

  handlePhoneChange = phoneNumber => {
    this.props.updateData({ phoneNumber });
    this.updatePhoneValidation();
  };

  handleChangeBirthday = date => {
    const dateFormat = date.valueText ? moment(date.valueText).format('YYYY-MM-DD') : null;
    if (dateFormat > getDateToday()) {
      this.props.snackBarStatus({
        payload: {
          enable: true,
          title: 'The birthday date must not be higher than the current date',
          type: 'error',
        },
      });
    } else {
      this.props.updateData({ birthDate: dateFormat });
    }
  };

  handleCountryChange = (id) => this.props.updateData({ countryPublicId: id });

  handleStateChange = (id) => this.props.updateData({ statePublicId: id });

  handlePasswordChange = e => {
    const value = e.target.value;
    const passwordValidation = {};
    PASSWORD_REQUIREMENTS.forEach(term => {
      passwordValidation[term] = Helpers.PasswordCheck(term, value);
    });
    this.setState({ password: value, passwordValidation }, this.updatePasswordValidation);
  };

  handlePasswordConfirmChange = e => {
    const value = e.target.value;
    this.setState({
      passwordConfirmation: value,
      passwordValidation: {
        ...this.state.passwordValidation,
        passwordsMatch: this.state.password === value && value !== ''
      }
    }, this.updateConfirmationPasswordValidation);
  };

  handleContactPreferenceChange = e => {
    this.props.updateData({ contactPreference: e.target.value });
  };

  handlePrivacyPolicyChange = e => this.setState({ privacyPolicyAccepted: e.target.checked });

  handleOpenPrivacyPolicyDialog = e => {
    e.preventDefault();
    this.setState({ isModalOpen: true });
  };

  handleCloseModal = () => {
    this.setState({ isModalOpen: false });
  };

  getPasswordValidationStateClass = param => (this.state.passwordValidation[param]) ? 'active' : '';

  renderForm = () => {
    const {
      firstName,
      lastName,
      email,
      phoneNumber,
      birthDate,
      street,
      city,
      postalCode,
      countryPublicId,
      statePublicId,
      intl,
    } = this.props;

    const { fieldsValidation, password, passwordConfirmation } = this.state;

    return (
      <section className="form-wrapper">
        <div className="form-row">
          <div className="form-column">
            <InputIconGridless
              type="text"
              name="firstName"
              value={firstName || ''}
              placeholder={intl.formatMessage({ id: 'label.firstName' })}
              icon="firstName"
              onChange={this.handleChange}
              tooltipTitle="Name"
            />
          </div>

          <div className="form-column">
            <InputIconGridless
              type="text"
              name="lastName"
              value={lastName}
              placeholder={intl.formatMessage({ id: 'label.lastName' })}
              icon="lastName"
              onChange={this.handleChange}
              tooltipTitle="Last name"
            />
          </div>
        </div>

        <div className="form-row">
          <div className="form-column">
            <InputIconGridless
              type="text"
              name="email"
              value={email}
              placeholder={intl.formatMessage({ id: 'label.email' })}
              icon="email"
              onChange={this.handleChange}
              tooltipTitle="Email"
            />
          </div>

          <div className="form-column">
            <div className={`input-phone-wrapper ${fieldsValidation.phone ? '' : 'invalid'}`}>
              <InputPhone
                name="phoneNumber"
                placeholder={intl.formatMessage({ id: 'label.phoneNumber' })}
                value={phoneNumber}
                onInputPhoneChange={this.handlePhoneChange}
                useV3
              />
            </div>
          </div>
        </div>

        <div className="form-row">
          <div className="form-column">
            <CountryPicker
              className="dropdown-country"
              placeholder={intl.formatMessage({ id: 'label.country' })}
              value={countryPublicId}
              onChange={this.handleCountryChange}
            />
          </div>

          <div className="form-column">

            <StatePicker
              className="dropdown-province"
              countryId={countryPublicId}
              placeholder={intl.formatMessage({ id: 'label.state' })}
              value={statePublicId}
              onChange={this.handleStateChange}
            />
          </div>
        </div>

        <div className="form-row">
          <div className="form-column">
            <InputIconGridless
              type="text"
              name="street"
              value={street}
              placeholder={intl.formatMessage({ id: 'label.streetAddress' })}
              icon="street"
              onChange={this.handleChange}
              tooltipTitle="Street"
            />
          </div>

          <div className="form-column">
            <InputIconGridless
              type="text"
              name="city"
              placeholder={intl.formatMessage({ id: 'label.city' })}
              value={city}
              icon="city"
              onChange={this.handleChange}
              tooltipTitle="City"
            />
          </div>
        </div>

        <div className="form-row">
          <div className="form-column">
            <InputIconGridless
              type="text"
              name="postalCode"
              placeholder={intl.formatMessage({ id: 'label.postalCode' })}
              value={postalCode}
              icon="postal-code"
              onChange={this.handleChange}
              tooltipTitle="Postal Code"
            />
          </div>

          <div className="form-column">
            <BirthdayPicker placeholder={intl.formatMessage({ id: 'label.dateOfBirth' })} value={birthDate} onChange={this.handleChangeBirthday} />
          </div>
        </div>

        <div className="form-row">
          <div className="form-column">
            <InputIconGridless
              className={`${fieldsValidation.password ? '' : 'validation-form'}`}
              type="password"
              title="password"
              icon="password"
              focused={focus}
              placeholder={intl.formatMessage({ id: 'label.password' })}
              value={password}
              onChange={this.handlePasswordChange}
            />
          </div>
          <div className="form-column">
            <InputIconGridless
              className={`${fieldsValidation.passwordConfirmation ? '' : 'validation-form'}`}
              type="password"
              title={intl.formatMessage({ id: 'label.passwordConfirm' })}
              icon="password"
              focused={focus}
              placeholder={intl.formatMessage({ id: 'label.passwordConfirm' })}
              value={passwordConfirmation}
              onChange={this.handlePasswordConfirmChange}
            />
          </div>
        </div>
      </section>
    )
  };

  render() {
    const {
      loading,
      groupInfoLoading,
      isLiteMode,
      group,
      profilePictureUrl,
      firstName,
      lastName,
      contactPreference,
      intl,
    } = this.props;

    const name = group.organization_general_group ? group.organization.name : group.name;

    if (loading || groupInfoLoading) {
      return <LoadingScreen loadingText="Loading..." />
    }

    return (
      <React.Fragment>
        <Scrollbars autoHeight autoHide autoHeightMin={0} autoHeightMax="100vh">
          <section className="complete-profile-page">
            <header className="page-header">

              <LanguageSwitcher />

              <figure>
                <img src={profilePictureUrl ? profilePictureUrl : memberImg} alt={`${lastName} ${firstName}`} />
              </figure>
            </header>

            <article className="page-description">
              {intl.formatMessage({ id: 'text.completeProfile' }, { name })}
            </article>

            {this.renderForm()}

            <section className="sect-password-requirements">
              <div className="title">{intl.formatMessage({ id: 'text.passwordRequireAtLeast'})}:</div>
              <ul>
                <li className={this.getPasswordValidationStateClass('lowercase')}>
                  {intl.formatMessage({ id: 'text.validation.oneLowercaseLetter'})}
                </li>
                <li className={this.getPasswordValidationStateClass('uppercase')}>
                  {intl.formatMessage({ id: 'text.validation.oneUppercaseLetter'})}
                </li>
                <li className={this.getPasswordValidationStateClass('specialCharacterOrNumber')}>
                  {intl.formatMessage({ id: 'text.validation.numberOrSpecialCharacter' })}
                </li>
                <li className={this.getPasswordValidationStateClass('length')}>
                  {intl.formatMessage({ id: 'text.validation.minCharacter' }, { num: 8 })}
                </li>
                <li className={this.getPasswordValidationStateClass('passwordsMatch')}>
                  {intl.formatMessage({ id: 'text.validation.fieldsMatch' })}
                </li>
              </ul>
            </section>

            <section className="sect-contact-preference">
              <div className="title">{intl.formatMessage({ id: 'text.question.contactMethod' })}</div>

              <div className="controls-wrapper" onChange={this.handleContactPreferenceChange}>
                {!isLiteMode && (
                  <React.Fragment>
                    <input id="contact-by-sms" name="contact-preference" type="radio" value="sms"
                           defaultChecked={contactPreference === 'sms'} />
                    <label className="option-sms" htmlFor="contact-by-sms">{intl.formatMessage({ id: 'label.texting' })}</label>
                  </React.Fragment>
                )}
                <input id="contact-by-email" name="contact-preference" type="radio" value="email"
                       defaultChecked={contactPreference === 'email'} />
                <label className="option-email" htmlFor="contact-by-email">{intl.formatMessage({ id: 'label.email' })}</label>
              </div>
            </section>

            <section className="sect-privacy-policy">
              <Checkbox
                className="check-section"
                color="default"
                onChange={this.handlePrivacyPolicyChange}
              />
              {intl.formatMessage({ id: 'label.acceptPrefix' })}
              <button onClick={this.handleOpenPrivacyPolicyDialog}>
                <u className="underline-text privacy-policy-link" key="privacyPolicy">
                  {intl.formatMessage({ id: 'label.privacyPolicy' })}
                </u>
              </button>
            </section>

            <footer className="page-footer">
              <ButtonSimple className="button-submit" text={intl.formatMessage({ id: 'action.continue' })} onClick={this.submit} />
              <br />
              <ButtonSimple
                className="button-login"
                buttonStyle="transparent"
                text={`${intl.formatMessage({ id: 'text.question.alreadyHaveAccount'})} ${intl.formatMessage({ id: 'action.login' })}`}
                onClick={redirectToLogin}
              />
            </footer>
          </section>
        </Scrollbars>

        <SnackBar />

        <SimpleModal
          title="Privacy Policy"
          content={ReactHtmlParser(DummyContent.text)}
          open={this.state.isModalOpen}
          handleClose={this.handleCloseModal}
          scroll="paper"
        />
      </React.Fragment>
    );
  }
}

const mS = state => ({
  firstName: state.completeProfileReducer.firstName,
  lastName: state.completeProfileReducer.lastName,
  birthDate: state.completeProfileReducer.birthDate ? state.completeProfileReducer.birthDate : null,
  phoneNumber: state.completeProfileReducer.phoneNumber,
  email: state.completeProfileReducer.email,
  street: state.completeProfileReducer.street,
  city: state.completeProfileReducer.city,
  postalCode: state.completeProfileReducer.postalCode,
  province: state.completeProfileReducer.province,
  selectedState: state.completeProfileReducer.selectedState,
  contactPreference: state.completeProfileReducer.contactPreference,
  isLiteMode: state.completeProfileReducer.isLiteMode,
  preferenceNumber: state.completeProfileReducer.preferenceNumber,
  token: state.completeProfileReducer.token,
  code: state.completeProfileReducer.code,
  member: state.completeProfileReducer.member,
  loading: state.completeProfileReducer.loading,
  statePublicId: state.statesDictionaryReducer.statePublicId,
  countryPublicId: state.completeProfileReducer.countryPublicId,
  profilePictureUrl: state.completeProfileReducer.profilePictureUrl,

  group: state.invitationReducer.group,
  groupInfoLoading: state.invitationReducer.loading,
});

const mD = {
  requestGroupAction,
  getUserAndGroupInformation,
  createUserProfile,
  updateUserProfile,
  getStates,
  snackBarStatus,
  updateData,
};

export default withRouter(connect(mS, mD)(injectIntl(CompleteProfile)));
